<template>
  <div class="flex flex-col h-full">

    <div class="py-4 px-3">
      <div class="flex gap-2 items-center py-2 mb-4 border-b border-b-gray-200">
        <u-button
          :to="heading.link"
          variant="soft"
          icon="i-ri-arrow-left-line"
          color="charcoal"
          size="xs"
          :title="heading.tooltip"
        />
        <h3 class="font-serif text-xl flex-1">{{heading.text}}</h3>
      </div>

      <label class="flex items-center mb-2">
        <span class="text-xs flex-1 text-gray-500">Show Cover Photos</span>
        <u-toggle v-model="showCoverPhotos" size="xs" />
      </label>

      <core-search-input
        v-if="isCurrentUser"
        placeholder="Search Albums"
        @search="updateSearchQuery"
        @clear="updateSearchQuery({q: ''})"
        :loading="pending"
      />
    </div>
    <div class="flex-1 scrollbar-light overflow-y-auto -mr-[13px] firefox:pr-[13px] pr-[7px] scrollbar-gutter-stable" ref="listEl">
      <transition-group name="f-slide-fade">
        <core-contextual-loading-box v-if="pending" size="xs" key="loading" :loading-text="`${searchTerm ? 'Searching' : 'Loading'} Albums`" />
        <core-contextual-error-box v-else-if="error" size="xs" key="error" :action="refresh" />
        <core-empty-state
          v-else-if="searchTerm && !navigationAlbums.length"
          heading="No Results"
          description="We couldn't find any albums matching your search."
          :icon="COMMON_ICONS.album"
          size="xs"
        />

        <core-list v-else :items="navigationAlbums" :scroller-elem="listEl" :disable-approach-bottom="pending || isGettingMore || !hasMore" @approach-bottom="getMore" container-class="grid" grid-gap-class="gap-0" item-class="min-w-0">
          <template #default="{ item }">
            <album-navigation-item :item="item" :album-id="item.id" :share-token="shareToken" :show-cover-photos="showCoverPhotos" :disable-nesting="Boolean(searchTerm)" />
          </template>
        </core-list>

      </transition-group>
    </div>
  </div>
</template>

<script setup>
  import {useStorage} from '@vueuse/core';

  const listEl = ref();
  const showCoverPhotos = useStorage(
      'core-web-ui-album-context-navigator-show-cover-photos',
      true
  );

  const {user_id_or_slug: userId, share_token: shareToken} = useRoute().params;

  const albumsStore = useAlbumsStore();
  const {albums, album, hasMore} = storeToRefs(albumsStore);

  const tokenAlbum = ref(null);

  watch(album, newVal => {
    if (shareToken && newVal.share_token === shareToken) {
      tokenAlbum.value = newVal;
    }
  },
  {
    immediate: true
  });

  const navigationAlbums = computed(() => {
    if (shareToken && tokenAlbum.value) {
      return [tokenAlbum.value];
    }

    return albums.value || [];
  });

  //search
  const searchTerm = ref('');
  function updateSearchQuery({q}) {
    searchTerm.value = q;
  }

  const isGettingMore = ref(false);

  const {refresh, pending, error} = await useAsyncData(
    'albumsNav',
    async () => {
      if (shareToken && album.value.share_token === shareToken) {
        albumsStore.clearAlbums();
      } else {
        await albumsStore.getAlbums({userId, search: searchTerm.value});
      }

      //load nested albums
      if (!searchTerm.value) {
        if (!shareToken) {
          let rootAlbumId;

          if (album.value.parent_album) {
            if (album.value.parent_album.ancestor_ids.length) {
              rootAlbumId = album.value.parent_album.ancestor_ids[0];
            } else {
              rootAlbumId = album.value.parent_album.id;
            }
          } else {
            rootAlbumId = album.value.id;
          }
          while (!albums.value?.find(a => a.id === rootAlbumId) /*&& hasMore.value*/) {
            await getMore();
          }
        }

        const promises = [];

        //load nested albums
        if (album.value.albums_count) {
          promises.push(albumsStore.getNestedAlbums({
            parentAlbumId: album.value.id,
            userId: album.value.user.id,
            shareToken
          }));
        }

        //load parent album and ancestors
        if (!shareToken && album.value.parent_album) {
          const ids = [album.value.parent_album.id, ...album.value.parent_album.ancestor_ids];
          for (const id of ids) {
            promises.push(albumsStore.getNestedAlbums({parentAlbumId: id, userId: album.value.user.id}));
          }
        }

        await Promise.all(promises);
      }
    },
    {
      watch: [searchTerm],
      server: false
    }
  );

  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    try {
      isGettingMore.value = true;
      await albumsStore.getAlbums({nextPage: true});
    } catch (e) {
      useErrorToast().add('There was an issue getting more albums.');
    } finally {
      isGettingMore.value = false;
    }
  }

  const isCurrentUser = computed(() => album.value.user.id === useSessionStore().currentUserId);
  const heading = computed(() => {
    if (isCurrentUser.value) {
      return {
        text: 'Albums',
        link: useBuildRoute().toAlbums(),
        tooltip: 'Back to Albums'
      };
    }

    if (shareToken) {
      return {
        text: 'Visited Albums',
        link: `/users/${userId}/albums/visited`,
        tooltip: `Back to Your Visited Albums`
      };
    }

    return {
      text: album.value.user.name,
      link: useBuildRoute().toUserProfile({user: album.value.user}),
      tooltip: `Back to Profile of ${album.value.user.name}`
    };
  });

  async function scrollToCurrentAlbum() {
    await nextTick();
    if (listEl.value) {
      const albumEl = listEl.value.querySelector(`[data-album-id="${album.value.id}"]`);
      if (albumEl) {
        albumEl.scrollIntoView({behavior: 'instant', block: 'center'});
      }
    }
  }

  useEventBus(ALBUM_EVENTS.navigationRefresh).on(() => {
    resetNavigation();
  });

  async function resetNavigation() {
    //reset search
    searchTerm.value = '';

    //clear all nested albums
    albumsStore.clearNestedAlbums();

    //reset top level
    await refresh();

    //scroll to current element
    scrollToCurrentAlbum();
  }

  onMounted(async () => {
    await waitFor(10);
    await scrollToCurrentAlbum();
  });
</script>
